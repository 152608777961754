
import { defineComponent } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '@/assets/util'

export default defineComponent({
  setup() {
    return {
      ...setupTranslationPrefixer('Form'),
    }
  },
})
