import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { useRoute } from '@nuxtjs/composition-api'

// todo: consider moving this code into a library for better re-usability

export function fieldComponentName(field) {
  return upperFirst(camelCase(`Formie ${field.__typename}`))
}

export function fieldVisible(field) {
  return !['disabled', 'hidden'].includes(field.visibility)
}

export function fieldName(field, parentField) {
  return `__${field.__typename}${parentField ? `__${parentField.handle}` : ''}__${field.handle}`
}

const labelPositionMap = {
  'verbb\\formie\\positions\\AboveInput': 'before',
  'verbb\\formie\\positions\\BelowInput': 'after',
  'verbb\\formie\\positions\\Hidden': 'hidden',
}

export function fieldLabelPosition(settings, field, fallback = 'before') {
  return labelPositionMap[field.labelPosition || settings.defaultLabelPosition] || fallback
}

const helpPositionMap = {
  'verbb\\formie\\positions\\AboveInput': 'before',
  'verbb\\formie\\positions\\BelowInput': 'after',
}

export function fieldHelpPosition(settings, field, fallback = 'before') {
  return helpPositionMap[field.instructionsPosition || settings.defaultInstructionsPosition] || 'before'
}

export function fieldValidationArray(field) {
  return [field.required ? ['^required', 'trim'] : ['optional']]
}

export function fieldTextValidationArray(field) {
  return [field.limit && field.limitType === 'characters' ? `max:${field.limitAmount},length` : undefined].filter((validation) => validation)
}

export function fieldOptions(field) {
  return Object.fromEntries(field.options.map(({ label, value }) => [value, label]))
}

export function fieldHiddenDefaultValue(field) {
  switch (field.defaultOption) {
    case 'currentUrl':
      return useRoute().value?.fullPath

    case 'currentUrlNoQueryString':
      return useRoute().value?.path

    default:
      return field.defaultValue
  }
}
